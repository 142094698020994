import { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import AnimateHeight from 'react-animate-height';

export default function ProductsItem(props) {

    const { width } = useWindowDimensions();
    const [cardTitleFont, setCardTitleFont] = useState(width> 600  ?  23 : 14);
  
    // update dark mode when system preference changes
    useEffect(() => {
      setCardTitleFont(width> 600  ?  23 : 14);
    }, [width]);

    const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        onClick={() => setOpen(!open)}
        style={{
          width: `100%`,
          height:'auto',
          position:'relative',
          display: "flex",
          paddingTop:'25%',
          backgroundImage: `url('${props.imageUrl}')`,
          backgroundRepeat:'no-repeat',
          backgroundPosition:'center center',
          backgroundSize:'100% auto',
        }}
      >
        <b
          style={{
              position: 'absolute',
              bottom:7,
              left:24,
              color: '#fff',
              fontSize: `${cardTitleFont}px`,
          }}
        >
        {props.title}
        </b>
        {!open && <b
          style={{
              position: 'absolute',
              bottom:3,
              right:12,
              color: '#fff',
              fontSize: `${cardTitleFont/2}px`,
          }}
        >Ürünleri Görmek İçin Tıklayınız</b>}
      </div>

      <AnimateHeight duration={ 500 } easing="ease" height={ open ? 'auto' : 0 }>
       <div
        style={{
          width: `calc(100% - 48px)`,
          padding: 24,
          display: "flex",
          flexDirection: 'column',
          gap:'16'
        }}
      >
        {props.products.map(p => 
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between', fontSize: `${cardTitleFont}px`,}}>
                <div style={{display:'flex',flexFlow:'row wrap',alignItems:'flex-end'}}> 
                    <div>{p.name}</div>
                    {p.description && 
                        <div style={{fontSize:cardTitleFont/2,opacity: 0.7, marginBottom: width > 600 ? 5 : 2.1}}>
                            &nbsp;({p.description})
                        </div>
                    }
                </div>
                <div style={{whiteSpace:'nowrap',marginLeft: 10}}>
                    {p.price} TL
                </div>
            </div>
        )}

      </div></AnimateHeight>
    </div>
  );
}
