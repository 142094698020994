import titleBg from "../assets/title-bg.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";


export default function SubMenuItem(props) {

    const { width } = useWindowDimensions();
    
    const [cardSize, setCardSize] = useState(width > 600  ?  200 : 140);
    const [cardTitleHeight, setCardTitleHeight] = useState(width > 600  ? 80 : 60);
    const [cardTitleFont, setCardTitleFont] = useState(width> 600  ?  23 : 16);
  
    // update dark mode when system preference changes
    useEffect(() => {
      setCardSize(width > 600  ?  200 : 140);
      setCardTitleHeight(width > 600  ? 80 : 60);
      setCardTitleFont(width> 600  ?  22 : 16);
    }, [width]);
    
  return (
    <Link
      to={`/menu/${props.menuId}/${props.subMenuId}/products`}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: 'none',
        color: 'inherit'
      }}
    >
      <div
        style={{
          height: `calc(${cardSize}px - ${props.borderLineThick * 5}px)`,
          width: `calc(${cardSize}px - ${props.borderLineThick * 5}px)`,
          objectFit: 'cover',
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `${props.borderLineThick}px solid rgb(29, 34, 43)`,
          padding: props.borderLineThick * 4,
        }}
      >
        <div
          style={{
            height: `calc(100% - ${props.borderLineThick * 5}px)`,
            width: `calc(100% - ${props.borderLineThick * 3}px)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            border: `${props.borderLineThick * 3}px solid rgb(29, 34, 43)`,
          }}
        >
          <img
            src={props.imageUrl}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
            alt={props.link}
          />
        </div>
      </div>

      <div
        style={{
          width: `${cardSize}px`,
          height: `${cardTitleHeight}px`,
          marginTop: `${cardTitleFont - 10}px`,
          display: "flex",
          justifyContent: "center",
          fontSize: `${cardTitleFont}px`,
          backgroundImage: `url(${titleBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%'
        }}
      >
        <div style={{marginTop: 1,color:'#fff'}}>{props.title}</div>
      </div>
    </Link>
  );
}
