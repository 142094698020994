import { useNavigate } from "react-router-dom";
import logo from "../assets/home-logo.png";

function Home() {
  const nav = useNavigate();

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: '100px',
          position: 'relative',
          paddingBottom: '28%',
          background: "rgb(29, 34, 43)",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "calc(100% - 40px)",
            top: 20,
            position: 'absolute',
            objectFit: 'cover'
          }}
          src={logo}
          alt='logo-banner'
        />
      </div>

      <div
        style={{
          width: "200px",
          height: "200px",
          border: "10px solid rgb(233 96 37)",
          display: "flex",
          alignItems: "center",
          borderRadius: "50%",
          justifyContent: "center",
          background: "rgb(29, 34, 43)",
          marginTop: 'auto',
        }}
        onClick={() => nav('/menu')}
      >
        <b style={{ color: "white", fontSize: 60 }}>Menu</b>
      </div>

      <div
        style={{
          width: "calc(100% - 50px)",
          marginTop: 'auto',
          marginBottom: '20px',
          height: "50px",
          background: "rgb(29, 34, 43)",
        }}
      >
        <a href="https://www.instagram.com/cafeqline/" target="_blank"><img style={{
          width: "calc(90% - 50px)",
          marginTop: '5px',
          marginBottom: '20px',
          height: "40px",
        }} src={"/instagram.svg"} />

        </a>
        <a href="https://goo.gl/maps/AXceuYvb2gWjjJ1cA" target="_blank"><img style={{
          marginTop: '5px',
          marginLeft: '-80px',
          marginBottom: '20px',
          height: "40px",
        }} src={"/google.png"} />

        </a>
      </div>
    </div>
  );
}

export default Home;
