import { useEffect, useState } from "react";
import Border from "../components/Border";
import MenuItem from "../components/MenuItem";


export default function Menu(props) {
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    fetch("data.json")
    .then((res) => res.json())
    .then((data) => setMenu(data.data.menu));
  }, []);
 

  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        width: "100%",
        height: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <Border color={`#fff`}>
        <div
          style={{
            padding: 25,
            display: "flex",
            width: "calc(100% - 50px)",
            height: "calc(100% - 50px)",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 30,
            }}
          >
            {menu &&
              menu.map((item) => (
                <div key={item.id} style={{ flex: "0 45%" }}>
                  <MenuItem
                    borderLineThick={2}
                    title={item.title}
                    imageUrl={item.imageUrl}
                    id={item.id}
                  />
                </div>
              ))}
          </div>
        </div>
      </Border>
    </div>
  );
}
