import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import homeIconWhite from "../assets/home-icon-white.svg";
import homeIcon from "../assets/home-icon.svg";
import leftIconWhite from "../assets/left-icon-white.svg";
import leftIcon from "../assets/left-icon.svg";
import bottom_logo_white from "../assets/logo_border_bottom-white.png";
import bottom_logo from "../assets/logo_border_bottom.png";
import rightIconWhite from "../assets/right-icon-white.svg";
import rightIcon from "../assets/right-icon.svg";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function Border(props) {

    const { width } = useWindowDimensions();
    
    const [borderWidth, setBorderWidth] = useState(width > 600  ?  60 : 40);
    const [pagePadding, setPagePadding] = useState(width > 600  ?  30 : 15);

    // update dark mode when system preference changes
    useEffect(() => {
        setBorderWidth(width > 600  ?  60 : 40);
        setPagePadding(width > 600  ?  30 : 15);
    }, [width]);


  return (
    <div
      style={{
        width: `calc(100% - ${pagePadding * 2}px`,
        height: `calc(100% - ${pagePadding * 2}px`,
        overflow:'auto',
        padding: pagePadding,
        position: "relative",
      }}
    >
      <div style={{ width: "100%", height: borderWidth }}>
        <HorizontalLine
          homeLink={`home link`}
          leftLink={`active`}
          rightLink={`not-active`}
          isTop={true}
          color={props.color}
        />
      </div>
      <div
        style={{
          width: "100%",
          minHeight: `calc(100% - ${borderWidth * 2}px)`,
          display: "flex",
          flexDirection: "row",
          position:'relative',
          
        }}
      >
        <div style={{  width: borderWidth, position:"absolute",top:0,bottom:0,left:0}}>
          <VerticalLine color={props.color} />
        </div>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            width: '100%',
            padding:`0px ${borderWidth}px`,
          }}
        >

          {props.children}
        </div>
        <div style={{ width: borderWidth,position:"absolute",top:0,bottom:0,right:0 }}>
          <VerticalLine color={props.color} />
        </div>
      </div>

      <div style={{ width: "100%", height: borderWidth }}>
        <HorizontalLine isTop={false} color={props.color} />
      </div>
    </div>
  );
}

function VerticalLine(props) {

    const { width } = useWindowDimensions();
    
    const [borderWidth, setBorderWidth] = useState(width > 600  ?  60 : 40);
    const [borderLineThick, setBorderLineThick] = useState(width > 600  ?  2 : 1);
  
    // update dark mode when system preference changes
    useEffect(() => {
        setBorderWidth(width > 600  ?  60 : 40);
        setBorderLineThick(width > 600  ?  2 : 1);
    }, [width]);

  return (
    <div
      style={{
        width: borderWidth - borderLineThick * 2,
        height: `100%`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderLeft: `${borderLineThick}px solid ${props.color}`,
        borderRight: `${borderLineThick}px solid ${props.color}`,
      }}
    >
      <div
        style={{
          width: borderWidth - borderLineThick * 2,
          height: "60%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexBasis: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "60%",
          }}
        >
          <div
            style={{
              height: `${borderLineThick}px`,
              width: "100%",
              backgroundColor: `${props.color}`,
            }}
          />
          <div
            style={{
              height: `${borderLineThick}px`,
              width: "100%",
              backgroundColor: `${props.color}`,
            }}
          />
        </div>

        <div
          style={{
            flexGrow: 1,
            flexBasis: 0,
            border: `${borderLineThick}px solid ${props.color}`,
          }}
        />

        <div
          style={{
            flexGrow: 1,
            flexBasis: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "60%",
          }}
        >
          <div
            style={{
              height: `${borderLineThick}px`,
              width: "100%",
              backgroundColor: `${props.color}`,
            }}
          />
          <div
            style={{
              height: `${borderLineThick}px`,
              width: "100%",
              backgroundColor: `${props.color}`,
            }}
          />
        </div>
      </div>
    </div>
  );
}

function HorizontalLine(props) {

  const nav = useNavigate();

  const { width } = useWindowDimensions();
    
  const [borderWidth, setBorderWidth] = useState(width > 600  ?  60 : 40);
  const [borderLineThick, setBorderLineThick] = useState(width > 600  ?  2 : 1);
  const [iconSizeValue, setIconSizeValue] = useState(width > 600  ?  20 : 15);
  const [logoWidth, setLogoWidth] = useState(width > 600  ?  150 : 75);
  const [logoHeight, setLogoHeight] = useState(width > 600  ?  50 : 35);

  // update dark mode when system preference changes
  useEffect(() => {
      setBorderWidth(width > 600  ?  60 : 40);
      setBorderLineThick(width > 600  ?  2 : 1);
      setIconSizeValue(width > 600  ?  20 : 15);
      setLogoWidth(width > 600  ?  150 : 75);
      setLogoHeight(width > 600  ?  50 : 35);
  }, [width]);


  return (
    <div
      style={{
        width: `calc(100% - ${borderLineThick * 2}px)`,
        height: borderWidth - borderLineThick * 2,
        border: `${borderLineThick}px solid ${props.color}`,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ borderRight: `${borderLineThick}px solid ${props.color}` }}>
        <div
          style={{
            width: borderWidth - borderLineThick * 4,
            height: borderWidth - borderLineThick * 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `${borderLineThick}px solid ${props.color}`,
          }}
        >
          {props.leftLink && (
            <div
              style={{ width: iconSizeValue, height: iconSizeValue }}
              onClick={() => nav(-1)}
            >
              <img
                src={props.color === "#fff" ? leftIconWhite : leftIcon}
                style={{ width: iconSizeValue, height: iconSizeValue }}
                alt='left-icon'
              />
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          width: `calc(100% - ${borderWidth * 1}px)`,
          height: `100%`,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flexGrow: 1, flexBasis: 0 }} />
        <div
          style={{
            flexGrow: 1,
            flexBasis: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderLeft: `${borderLineThick}px solid ${props.color}`,
            borderRight: `${borderLineThick}px solid ${props.color}`,
          }}
        >
          {props.isTop ? (
            <Link
              to={'/'}
              style={{ width: iconSizeValue * 2, height: iconSizeValue * 2 }}
            >
              <img
                src={props.color === "#fff" ? homeIconWhite : homeIcon}
                style={{
                  fill: props.color,
                  width: iconSizeValue * 2,
                  height: iconSizeValue * 2,
                }}
                alt='home-icon'
              />
            </Link>
          ) : (
            <img
              src={props.color === '#fff' ? bottom_logo_white : bottom_logo}
              alt='logo'
              style={{
                color: props.color,
                width: logoWidth,
                height: logoHeight,
                objectFit: "inherit",
                padding: "0px 5px",
              }}
            />
          )}
        </div>
        <div style={{ flexGrow: 1, flexBasis: 0 }} />
      </div>

      <div style={{ borderLeft: `${borderLineThick}px solid ${props.color}` }}>
        <div
          style={{
            width: borderWidth - borderLineThick * 4,
            height: borderWidth - borderLineThick * 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `${borderLineThick}px solid ${props.color}`,
          }}
        >
          {props.rightLink && (
            <div
              style={{ width: iconSizeValue, height: iconSizeValue }}
            >
              <img
                src={props.color === "#fff" ? rightIconWhite : rightIcon}
                style={{
                  fill: props.color,
                  width: iconSizeValue,
                  height: iconSizeValue,
                }}
                alt='right-icon'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
