import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Border from "../components/Border";
import ProductsItem from "../components/ProductsItem";


export default function Products(props) {

  const { id,subMenuId } = useParams();

  const [product, setProduct] = useState(null);

  useEffect(() => {
    fetch("/data.json")
    .then((res) => res.json())
    .then((data) => {
      const p = (subMenuId === '-1') ? 
        data.data.products.filter(s => s.menuId.toString() === id) : 
        data.data.products.filter(s => s.menuId.toString() === id && s.subMenuId.toString() === subMenuId);
      setProduct(p);
    });
  }, [id, subMenuId]);


  return (
    <div
      style={{
        width: "100%",
        height:"100%",
        display: "flex"
      }}
    >
      <Border color={`rgb(29, 34, 43)`}>
          {product && product.map(item => (
              <ProductsItem
                menuId={id}
                subMenuId={subMenuId}
                title={item.title}
                imageUrl={item.imageUrl}
                products={item.product}
              />
          ))}
      </Border>
    </div>
  );
}
