import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/HomePage";
import Menu from "./views/Menu";
import Products from "./views/Products";
import SubMenu from "./views/SubMenu";

function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/menu' element={<Menu titleColor={'rgb(29, 34, 43)'} backgroundColor={'rgb(29, 34, 43)'} />} />
          <Route path='/menu/:id' element={<SubMenu />} />
          <Route path='/menu/:id/:subMenuId/products' element={<Products />} />
        </Routes>
    </Router>
  );
}

export default App;
