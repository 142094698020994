import Border from "../components/Border";
import SubMenuItem from "../components/SubMenuItem";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function SubMenu(props) {

  const { id } = useParams();//for filter filtre

  const [subMenu, setSubMenu] = useState(null);

  useEffect(() => {
    fetch("/data.json")
    .then((res) => res.json())
    .then((data) => {
      const sub = data.data.submenu.filter(s => s.menuId.toString() === id);
      setSubMenu(sub);
    });
  }, [id]);


  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        width: "100%",
        height: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <Border color={`rgb(29, 34, 43)`}>
          <div style={{padding:25, display: "flex", width: 'calc(100% - 50px)', height:'calc(100% - 50px)'}}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 30,
          }}
        >
          {subMenu && subMenu.map((item) => (
            <div style={{ flex: "0 45%" }}>
              <SubMenuItem
                borderLineThick={2}
                title={item.title}
                imageUrl={item.imageUrl}
                menuId={item.menuId}
                subMenuId={item.id}
              />
            </div>
          ))}
        </div>
        </div>
      </Border>
    </div>
  );
}
